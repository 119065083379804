<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    v-model="selected"
    :menu-props="{ offsetY: true, overflowY: true, closeOnContentClick: true }"
    :loading="loading"
    :items="data"
    item-text="text"
    item-value="value"
  />
</template>

<script>
  import { GENDER } from "@/helpers/enums";

  export default {
    name: "GenderPicker",
    props: {
      value: String,
      loading: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: vm => ({
      selected: null,
      data: [
        { text: vm.$t("gender.male"), value: GENDER.male },
        { text: vm.$t("gender.female"), value: GENDER.female },
        { text: vm.$t("gender.other"), value: GENDER.other }
      ]
    }),
    watch: {
      value: {
        handler(v) {
          this.selected = v;
        },
        immediate: true
      }
    }
  };
</script>

<style scoped></style>
